<template>
  <div class="">
    <div class="">
    </div>
    <div v-if='formObj != null && !formLoading' >
      <!-- {{userProfile}} -->
      <BaseForm 
        :editObj='formObj' 
        :getBaseData='getBaseData' 
        ref='baseForm' 
        :noDiscription="true"
      />
      <!-- User Info: Input Fields -->
      <hr />
      <ResponsiveFormFields 
        v-if='formObj != null && !formLoading' 
        :defaultItem="defaultItem" 
        :editObj='formObj' 
        :getRestData='getRestData' 
        :formFields="restFields" 
        ref='restForm' 
      />     
      <!-- PERMISSION TABLE -->
      <PermissionsForm 
        v-if='formObj != null && !formLoading' 
        :editObj='formObj' 
        :getPermissionsData='getPermissionsData' 
        ref='permissionsForm' 
      />
    </div>
  </div>
</template>

<script>
import {
  BButton, BMedia, BAvatar, BRow, BCol, BFormGroup, BFormInput, BForm, BTable, BCard, BCardHeader, BCardTitle, BFormCheckbox,
} from 'bootstrap-vue'
import { avatarText } from '@core/utils/filter'
import vSelect from 'vue-select'
import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils'
import { ref } from '@vue/composition-api'
import { mapGetters, mapActions } from 'vuex'
import BaseForm from './BaseForm.vue';
import ResponsiveFormFields from './ResponsiveFormFields.vue';
import PermissionsForm from './PermissionsForm.vue';

export default {
  components: {
    BButton,
    BMedia,
    BAvatar,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BTable,
    BCard,
    BCardHeader,
    BCardTitle,
    BFormCheckbox,
    vSelect,
    BaseForm,
    ResponsiveFormFields,
    PermissionsForm,
  },
  data() {
    return {
      formLoading: false,
      formData: {},
      formObj: null,
      defaultItem: {},
      
    }
  },
  props: ['editObj', 'closeModal'],
  mounted() {
    this.formLoading = true;
    // console.log('this.editObj', this.editObj)
    if(this.editObj != null) {
      // console.log(this.filteredUser)
      this.formObj = {...this.editObj};
      this.formData.id = this.editObj.id
      this.formLoading = false;
    }else {
      this.formData = {}
      this.formObj = {
        status: true,
      };
      this.formLoading = false;
    }
  },
  methods: {
    ...mapActions(['getRoles', 'createRole']),
    formSubmit() {
      
      this.$refs.restForm.submit()
        .then(res => {
          if(res == true) {
            this.$refs.baseForm.submit()
              .then(res => {
                if(res == true) {
                  this.$store.commit('setModalLoading', true);
                  // this.$refs.baseForm.submit();
                  // this.$refs.restForm.submit();
                  setTimeout(() => {
                    this.$refs.permissionsForm.submit();
                    // console.log(this.formData.permissions)
                    this.createRole(this.formData)
                      .then(res => {
                        this.closeModal();
                        this.getRoles({});
                        this.$store.commit('setModalLoading', false);
                      })  
                  }, 100)
                }
              })
          }
        })
    },
    
    getBaseData(data) {
      this.formData.titles = data.titles;
      // this.formData.descriptions = data.descriptions;
    },
    getRestData(data) {
      this.formData = {
        ...this.formData,
        ...data,
      }
    },
    getPermissionsData(data) {
      this.formData.permissions = data;
    }
  },
  computed: {
    ...mapGetters(['userProfile', 'filteredUser', 'permissions', 'roles']),
    restFields() {
      return [
        {
          name: this.$t('status'), 
          type: 'checkbox', 
          rules: 'required', 
          model: 'status',
        },
      ]
    }
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
