<template>
  <div>
    <b-form @submit.prevent='submit'>
      <label for='file-input'>
        <span v-if='title'>
          {{title}}
        </span>
        <span v-else-if='$route.name == "Sections"'>
          {{$t('Media')}}
        </span>
        <span v-else>
          {{$t('Images')}}
        </span>
      </label>  
      <b-form-file
        ref="file-input"
        id='file-input'
        v-model="file"
        class="mb-2"
        :multiple='multiple ? multiple : false'
      />
      <!-- <b-button
        variant="primary"
        @click="file = null"
        size="sm"
      >
        {{$t('Reset')}}
      </b-button> -->

      <b-card class="p-1 " v-if='editObj != null'>
        <!-- <img v-if='$route.name == "Projects" && (editObj.images)' :src='(editObj.images[0] && editObj.images[0].url)' width="10%" height="100%"/> -->
        <img v-if='$route.name == "Projects" && editObj.featured_image != "null" && (editObj.featured_image_url)' :src='editObj.featured_image_url' width="10%" height="100%"/>
        <img v-if='$route.name == "Categories"' :src='editObj.icon_url' width="10%" height="100%"/>
        <img v-if='$route.name == "Sections"' :src='editObj.media_url' width="10%" height="100%"/>
        <img v-if='urlRoutes' :src='editObj.image_url' width="10%" height="100%"/>
          <!-- <div class="my-1" v-if='editObj.icon_url || (editObj.images[0] && editObj.images[0].url)'> -->
          <b-button
            variant="danger"
            class="btn-icon mx-2"
            @click="deleteImg"
            v-if=' $route.name == "Projects" && editObj.featured_image != "null" && (editObj.featured_image_url)'
          >
            <feather-icon :icon="btnIcon" />
          </b-button>
        <!-- </div> -->
      </b-card>
    </b-form>
  </div>
</template>

<script>
import { BFormFile, BButton, BForm, BCard, BCardFooter } from 'bootstrap-vue'
import {mapActions} from 'vuex'
export default {
  components: {
    BFormFile,
    BButton,
    BForm,
    BCard, BCardFooter
  },
  props: ['getImgData', 'editObj', 'getMediaData', 'multiple', 'title'],
  data: () => ({
    file: null,
    base64: {
      ext: '',
      file: '',
    },
    btnIcon: 'Trash2Icon',
  }),
  methods: {
    ...mapActions(['createProduct']),
    submit() {
      if(this.file) {
        if(this.$route.name == 'Sections') {
          this.getMediaData(this.file)
        }else if(this.file == 'delete') {
          this.getImgData('delete')
        }else {
          this.getBase64(this.file)
            .then(res => {
              this.getImgData(res)
              return true;
            })
        }
      }
      // this.getImgForm()
    },
    deleteImg() {
      this.file == null ? this.file = 'delete' : '';
      this.btnIcon == 'XIcon' ? this.btnIcon = 'Trash2Icon' : this.btnIcon = 'XIcon'
    } 
        
  },
  computed: {
    urlRoutes() {
      let routes = [
        'Labels',
        'Testmonials',
        'Banners'
      ];
      return routes.includes(this.$route.name);
    }
  }
}
</script>
 