<template>
  <div class="" v-if='!formLoading'>    
    <BaseForm
      v-if='formObj != null && !formLoading' 
      :editObj='formObj' 
      :getBaseData='getBaseData' 
      ref='baseForm'
    />
    <hr/>
    <ResponsiveFieldsForm 
      v-if='formObj != null && !formLoading' 
      :defaultItem="defaultItem" 
      :editObj='formObj' 
      :getRestData='getRestData' 
      :formFields="restFields" 
      ref='restForm' 
    />
    <ResponsiveFieldsForm 
      v-if='formObj != null && !formLoading && ((formObj && formObj.style == "exploreMore") || (editObj && editObj.style == "exploreMore"))' 
      :defaultItem="defaultItem" 
      :editObj='formObj.custom_fields' 
      :getRestData='getCustomData' 
      :formFields="customFields" 
      ref='customForm' 
    />
    <!-- <hr/> -->
    <ResponsiveFieldsForm 
      v-if='formObj != null && !formLoading && ((formObj && formObj.style == "strataFoundations") || (editObj && editObj.style == "strataFoundations"))' 
      :defaultItem="defaultItem" 
      :editObj='formObj.labels' 
      :getRestData='getlogosData' 
      :formFields="logoesFields" 
      ref='logosForm' 
    />
    <ResponsiveFieldsForm 
      v-if='formObj != null && !formLoading && ((formObj && formObj.style == "slider") || (editObj && editObj.style == "slider"))' 
      :defaultItem="defaultItem" 
      :editObj='formObj' 
      :getRestData='getSliderData' 
      :formFields="sliderField" 
      ref='sliderForm' 
    />
    <ResponsiveFieldsForm 
      v-if='formObj != null && !formLoading && ((formObj && formObj.style == "pageCover") || (editObj && editObj.style == "pageCover"))' 
      :defaultItem="defaultItem" 
      :editObj='formObj' 
      :getRestData='getCustomCover' 
      :formFields="pageCoverField" 
      ref='pageCoverForm' 
    />
    <!-- <b-row 
      v-if='formObj != null && !formLoading && ((formObj && formObj.style == "strataFoundations") || (editObj && editObj.style == "strataFoundations"))'
    >
      <b-col>
        <b-form-group
          :label="$t('Logo 1')"
          label-for='logo1'
        >
          <b-form-file
            ref="file-input"
            id='logo1'
            v-model="formData.logo1"
            class="mb-2"
          />
        </b-form-group>
      </b-col>
      <b-col>
        <b-form-group
          :label="$t('Logo 2')"
          label-for='logo2'
        >
          <b-form-file
            ref="file-input"
            id='logo2'
            v-model="formData.logo2"
            class="mb-2"
          />
        </b-form-group>
      </b-col>
    </b-row> -->
    <ImgForm 
      :editObj='formObj' 
      :getMediaData='getMediaData'
      :getImgData='getImgData' 
      ref='imgForm'
    />
    
  </div>
</template>

<script>
// import ResponsiveForm from './ResponsiveForm.vue'
import ResponsiveFieldsForm from './ResponsiveFieldsForm.vue';
import BaseForm from './BaseForm.vue';
import ProductRestForm from './ProductRestForm.vue';
import ImgForm from './ImgForm.vue';

import {
  BRow, BCol, BFormInput, BFormFile, BFormGroup,
} from 'bootstrap-vue';

import { mapActions, mapGetters } from 'vuex';

export default {
  components: {
    ResponsiveFieldsForm,
    BaseForm,
    ProductRestForm,
    ImgForm,
    BRow, BCol, BFormInput, BFormFile, BFormGroup,
  },
  data() {
    return {
      formObj: null,
      formLoading: false,
      formData: {},
      defaultItem: {
        
        // color: '#FFFFFF',
      }
    }
  },

  props: ['editObj', 'closeModal',],
  mounted() {
    
    if(this.editObj != null) {
      this.getSection(this.editObj.id)
        .then(res => {
          this.formObj = {...res.data.results};
          
          this.formObj.style = this.formObj.style.type
          this.formData.id = this.editObj.id;
          if(this.formObj.labels) {
            this.formObj.labels.forEach((el, index) => {
              this.formObj.labels[`logo${index+1}`] = el;
            })
          }
          if(this.formObj.custom_fields && this.formObj.custom_fields.cover_size) {
            this.formObj.cover_size = this.formObj.custom_fields.cover_size
            delete this.formObj.custom_fields;
          }
          if(this.formObj.custom_fields && this.formObj.custom_fields.slider_id) {
            this.formObj.slider =  {
              id: this.formObj.custom_fields.slider_id,
              key: this.formObj.custom_fields.key,
            }
            delete this.formObj.custom_fields;
          }
          this.getLabels({})
          this.getSliders({})
          setTimeout(() => {
            this.formLoading = false;
          }, 2000)
        })
    }else {
      this.formObj = {
        status: true
      }
    }
  },
  methods: {
    ...mapActions(['getPages', 'getSections', 'createSection', 'getSection', 'getLabels', 'getSliders']),
    formSubmit()  {
      this.$refs.restForm.submit()
        .then(res => {
          if(res == true) {
            if(res == true) {
              this.$store.commit('setModalLoading', true);
              this.$refs.restForm.submit();
              if((this.formObj && this.formObj.style == "exploreMore") || (this.editObj && this.editObj.style == "exploreMore")) {
                this.$refs.customForm.submit();
              }
              if((this.formObj && this.formObj.style == "strataFoundations") || (this.editObj && this.editObj.style == "strataFoundations")) {
                this.$refs.logosForm.submit();
              }
              if((this.formObj && this.formObj.style == "slider") || (this.editObj && this.editObj.style == "slider")) {
                this.$refs.sliderForm.submit();
              }
              if((this.formObj && this.formObj.style == "pageCover") || (this.editObj && this.editObj.style == "pageCover")) {
                this.$refs.pageCoverForm.submit();
              }
              this.$refs.imgForm.submit();
              this.$refs.baseForm.submit();
              console.log(this.formData)  
              setTimeout(() => {
                this.createSection(this.formData)
                  .then(res => {
                    this.closeModal();
                    this.getSections({});
                    this.$store.commit('setModalLoading', false);
                  })
              }, 300)
            }
          }
        })
    },
    
    getBaseData(data) {
      this.formData.titles = data.titles;
      this.formData.descriptions = data.descriptions;
    },
    getImgData(data) {
      console.log(data)
      this.formData.base64 = [];
      if(this.editObj && this.editObj.image) {
        this.formData.base64 = [
          {
            ...this.editObj.image,
            delete: true
          }
        ]
      }
        
      this.formData.base64 = [
        ...this.formData.base64,
        data,
      ];
    },
      
    getRestData(data) {
      this.formData = {
        ...this.formData,
        ...data,
        style: {
          type: data.style,
        }
      }
    },

    getCustomCover(data) {
      this.formData.cover_size = data.cover_size;
    },
    getCustomData(data) {
      this.formData.custom_fields = { ...data};
    },
    getSliderData(data) {
      this.formData.slider = data.slider;
    },
    getlogosData(data) {
      this.formData = {
        ...this.formData,
        ...data,
      }
      console.log('logos data', data)
    },
    getMediaData(data) {
      if(this.isFileImage(data)) {
        this.formData.media_type = 0;
      }else {
        this.formData.media_type = 1;
      }
      this.formData.base64 = data;

    },
    getRepeaterData(data) {
      this.formData.items = data;
    },
    checkNulldesc() {
      if(this.formData.descriptions.ar == null || this.formData.descriptions.en == null) {
        if(this.formData.descriptions.ar == null) {
          this.formData.descriptions.ar = ''
        }
        if(this.formData.descriptions.en == null) {
          this.formData.descriptions.en = ''
        }
      }
    },
    searchPages(searchText) {
      this.getPages({
        path: searchText,
        status: 1,
      })
    },
    searchLabels(searchText) {
      this.getLabels({
        path: searchText,
        status: 1,
      })
    },
    searchSliders(searchText) {
      this.getSliders({
        key: searchText ? searchText : undefined,
        status: 1,
      })
    },

  },

  computed: {
    ...mapGetters(['product', 'modalLoading', 'pages', 'labels', 'sliders']),
    sectionStyles() {
      return [
        {id: 11, title: 'Page Cover', value: 'pageCover'},
        {id: 5, title: 'Slider', value: 'slider'},
        {id: 3, title: 'Map', value: 'map'},
        {id: 4, title: 'Team', value: 'testmonials'},
        {id: 7, title: 'Statistics', value: 'exploreMore'},
        {id: 8, title: 'Txt L-Ph R-Title', value: 'strataFoundations'},
        {id: 9, title: 'Ph L-Txt R', value: 'aboutStrata'},
        {id: 10, title: 'Txt L-Ph R', value: 'foundationsPlan'},
        {id: 1, title: 'Soil Systems Projects', value: 'soilprojects'},
        {id: 1, title: 'Foundations Projects', value: 'foundationsprojects'},
        {id: 6, title: 'Txt Only', value: 'custom'},
        // {id: 12, title: 'Soil System Plan', value: 'plan'},
      ]
    },
    restFields() {
      return [
        // {name: 'titles', type: 'text', rules: 'required', model: 'ttiles', },
        // {name: 'path', type: 'text', rules: 'required', model: 'path' },
        {name: 'ord', type: 'number', rules: 'required', model: 'ord' },
        {name: 'status', type: 'checkbox', rules: 'required', model: 'status' },
        {
          name: 'pages', type: 'searchSelect', rules: 'required', 
          model: 'page_id', options: this.pages, label: 'titles', 
          searchItems: this.searchPages,
          cols: 6,
        },
        {
          name: 'Style', type: 'select', rules: 'required', 
          model: 'style', options: this.sectionStyles, label: 'title', 
          cols: 6,
        },
        { name: '', type: 'divider', cols: 12 },
      ]
    },
    customFields() {
      return [
        { name: 'Count Numbers', type: 'number', rules: '', model: 'count1', cols: 'auto'},
        { name: 'Count Numbers', type: 'number', rules: '', model: 'count2', cols: 'auto'},
        { name: 'Count Numbers', type: 'number', rules: '', model: 'count3', cols: 'auto'},
        { name: 'Count Numbers', type: 'number', rules: '', model: 'count4', cols: 'auto'},
        { name: 'Count Numbers', type: 'number', rules: '', model: 'count5', cols: 'auto'},
      ]
    },
    logoesFields() {
      return [
        {
          name: 'Logo 1', type: 'searchSelect', rules: '', 
          model: 'logo1', options: this.labels, label: 'title', 
          searchItems: this.searchLabels,
          cols: 6,
        },
        {
          name: 'Logo 2', type: 'searchSelect', rules: '', 
          model: 'logo2', options: this.labels, label: 'title', 
          searchItems: this.searchLabels,
          cols: 6,
        },
      ]
    },
    sliderField() {
      return [
        {
          name: 'Slider', type: 'searchSelectItem', rules: '', 
          model: 'slider', options: this.sliders, label: 'key', 
          searchItems: this.searchSliders,
          cols: 12,
        },
      ]
    },
    pageCoverField() {
      return [
        {
          name: 'Size', type: 'number', rules: 'between:39,100', 
          model: 'cover_size',  
          cols: 12,
        },
      ]
    }
  }

}
</script>