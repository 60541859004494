export default [
  // {
  //   title: 'Dashboard',
  //   // tag: 'Soon',
  //   // tagVariant: 'light-primary',
  //   route: 'Home',
  //   icon: 'HomeIcon',
  // },
  // {
  //   title: 'Sales',
  //   route: 'Orders',
  //   icon: 'ClipboardIcon',
  //   children: [
  //     {
  //       title: 'Orders',
  //       route: 'Orders',
  //       // icon: 'FileIcon',
  //     },
  //     {
  //       title: 'Order Status',
  //       route: 'OrderStatus',
  //       // icon: 'FileIcon',
  //     },
  //     {
  //       title: 'Customers',
  //       // tag: 'Soon',
  //       // tagVariant: 'light-primary',
  //       route: 'Customers',
  //       // icon: 'FileIcon',
  //     },
  //     {
  //       title: 'Offers ',
  //       // tag: 'Soon',
  //       tagVariant: 'light-primary',
  //       route: 'Offers',
  //       // icon: 'FileIcon',
  //     },
  //   ]
  // },
  // {
  //   title: 'Products',
  //   route: 'Products',
  //   icon: 'BoxIcon',
  //   children: [
  //     {
  //       title: 'All Products',
  //       route: 'Products',
  //       icon: 'BoxIcon',
  //     },
  //   ]
  // },
  // {
    //   title: 'Procurement',
    //   route: 'Reciepts',
    //   icon: 'TagIcon',
    //   children: [
      //     {
        //       title: 'Purchasing',
        //       route: 'Reciepts',
        //       // icon: 'FileIcon',
        //     },
        //     {
          //       title: 'Suppliers',
          //       route: 'Suppliers',
          //       // icon: 'FileIcon',
          //     },
          //   ]
          // },
  {
    title: 'Pages',
    icon: 'FileIcon',
    children: [
      {
        title: 'All Pages',
        // tag: 'Soon',
        // tagVariant: 'light-primary',
        route: 'Pages',
        permKeys: ['page']
      },
      {
        title: 'Sections',
        // tag: 'Soon',
        // tagVariant: 'light-primary',
        route: 'Sections',
        // icon: 'UsersIcon',
        permKeys: ['section']
      },
      {
        title: 'Sliders',
        icon: 'ImageIcon',
        children: [
          {
            title: 'All Sliders',
            route: 'Sliders',
            permKeys: ['slider']
          },
          {
            title: 'Banners',
            route: 'Banners',
            // icon: 'GridIcon',
            permKeys: ['banners']
          }  
        ],
        permKeys: ['banners', 'slider']
      },
    ],
    permKeys: ['banners', 'slider', 'page', 'section']
  },
  {
    title: 'Projects',
    icon: 'CreditCardIcon',
    children: [
      {
        title: 'Projects',
        route: 'Projects',
        permKeys: ['project']
      },
      {
        title: 'Categories',
        route: 'Categories',
        // icon: 'GridIcon',
        permKeys: ['category']
      },
      {
        title: 'Labels',
        route: 'Labels',
        // icon: 'FileIcon',
        permKeys: ['label']
      },
    ],
    permKeys: ['label', 'category', 'project']
  },
  
  {
    title: 'Jobs',
    icon: 'BriefcaseIcon',
    children: [
      {
        title: 'Jobs',
        route: 'Jobs',
        permKeys: ['job']
      },
      {
        title: 'Jobs Categories',
        route: 'JobCategories',
        icon: 'GridIcon',
        permKeys: ['jobCategory']
      },
    ],
    permKeys: ['jobCategory', 'job']
  },
  {
    title: 'Map Points',
    route: 'Solutions',
    icon: 'MapIcon',
    permKeys: ['solution']
  },
  
  {
    title: 'Testmonials',
    route: 'Testmonials',
    icon: 'UserCheckIcon',
    permKeys: ['testimonial']
  },
  
  {
    title: 'Contacts',
    route: 'Contacts',
    icon: 'AtSignIcon',
    children: [
      {
        title: 'Contact Us',
        route: 'ContactUs',
        // icon: 'FileIcon',
        permKeys: ['contactUs']
      },
      {
        title: 'Subscribtions',
        route: 'Subscribtions',
        // icon: 'FileIcon',
        permKeys: ['subscribe']
      },
    ],
    permKeys: ['contactUs', 'subscribe']
  },
  {
    title: 'Users',
    route: 'Accounts',
    icon: '',
    permKeys: ['accounts', 'users']
  },
  {
    title: 'Roles',
    route: 'Roles',
    icon: 'LockIcon',
    permKeys: ['roles']
  },
  {
    title: 'Settings',
    // tag: 'Soon',
    // tagVariant: 'light-primary',
    route: 'Settings',
    icon: 'SettingsIcon',
    permKeys: ['settings']
  },
]
