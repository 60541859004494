<template>
  <div class="" v-if='!formLoading'>
    <BaseForm 
      v-if='formObj != null && !formLoading' 
      :editObj='formObj' 
      :getBaseData='getBaseData' 
      ref='baseForm'
    />
    <hr/>
    <ResponsiveFieldsForm 
      v-if='formObj != null && !formLoading' 
      :defaultItem="defaultItem" 
      :editObj='formObj' 
      :getRestData='getRestData' 
      :formFields="restFields" 
      ref='restForm' 
    />
    <!-- <hr/> -->
    <ImgForm 
      v-if='formObj != null && !formLoading' 
      :editObj='formObj' 
      :getMediaData='getMediaData'
      :getImgData='getImgData' 
      ref='imgForm'
    />
    
  </div>
</template>

<script>
// import ResponsiveForm from './ResponsiveForm.vue'
import ResponsiveFieldsForm from './ResponsiveFieldsForm.vue';
import BaseForm from './BaseForm.vue';
import ProductRestForm from './ProductRestForm.vue';
import ImgForm from './ImgForm.vue';

import {
  BRow, BCol
} from 'bootstrap-vue';

import {mapActions,mapGetters} from 'vuex';

export default {
  components: {
    ResponsiveFieldsForm,
    BaseForm,
    ProductRestForm,
    ImgForm,
    BRow, BCol
  },
  data() {
    return {
      formObj: null,
      formLoading: false,
      formData: {},
      defaultItem: {
        // color: '#FFFFFF',
        status: true,
      }
    }
  },

  props: ['editObj', 'closeModal',],
  mounted() {
    console.log(this.editObj)
    if(this.editObj != null) {
      this.getBanner(this.editObj.id)
        .then(res => {
          this.formObj = res.data.results;
          // this.for
          this.formData.id = this.editObj.id;
          this.formObj.target = this.editObj.target.target;
          setTimeout(() => {
            this.formLoading = false;
          }, 100)
        })
      // this.getLabel(this.editObj.id)
      //   .then(res => {
      //     this.formData.id = this.editObj.id;
      //     this.formLoading = false;
      //   })
    }else {
      this.formObj = this.defaultItem;
    }
  },
  methods: {
    ...mapActions(['getBanners', 'createBanner', 'getBanner']),
    formSubmit()  {
      this.$refs.restForm.submit()
        .then(res => {
          if(res == true) {
            if(res == true) {
              this.$store.commit('setModalLoading', true);
              this.$refs.restForm.submit();
              this.$refs.imgForm.submit();
              this.$refs.baseForm.submit();
              console.log(this.formData)
              setTimeout(() => {
                this.createBanner(this.formData)
                  .then(res => {
                    this.closeModal();
                    this.getBanners({});
                    this.$store.commit('setModalLoading', false);
                  }).catch(err => {
                    console.log(err)
                  })
              }, 100)
            }
          }
        })
    },
    
    getBaseData(data) {
      this.formData.titles = data.titles;
      this.formData.descriptions = data.descriptions;
    },
    getImgData(data) {
      console.log(data)
      this.formData.base64 = [];
      if(this.editObj && this.editObj.image) {
        this.formData.base64 = [
          {
            ...this.editObj.image,
            delete: true
          }
        ]
      }
        
      this.formData.base64 = [
        ...this.formData.base64,
        data,
      ];
    },
      
    getRestData(data) {
      this.formData = {
        ...this.formData,
        ...data,
      }
    },
    getMediaData(data) {
      if(this.isFileImage(data)) {
        this.formData.media_type = 0;
      }else {
        this.formData.media_type = 1;
      }
      this.formData.base64 = data;
    },
    getRepeaterData(data) {
      this.formData.items = data;
    },
    checkNulldesc() {
      if(this.formData.descriptions.ar == null || this.formData.descriptions.en == null) {
        if(this.formData.descriptions.ar == null) {
          this.formData.descriptions.ar = ''
        }
        if(this.formData.descriptions.en == null) {
          this.formData.descriptions.en = ''
        }
      }
    },
    searchSliders(searchText) {
      this.getSliders({
        key: searchText,
        status: 1,
      })
    }
  },

  computed: {
    ...mapGetters(['product', 'modalLoading', 'sliders']),
    restFields() {
      return [
        // {name: 'titles', type: 'text', rules: 'required', model: 'ttiles', },
        // {name: 'path', type: 'text', rules: 'required', model: 'path' },
        // {name: 'ord', type: 'number', rules:   'required', model: 'ord' },
        {name: 'status', type: 'checkbox', rules: 'required', model: 'status' },
        {
          name: 'slider', type: 'searchSelect', rules: 'required', 
          model: 'slider_id', options: this.sliders, label: 'key', 
          searchItems: this.searchSliders,
          cols: 12,
        },
        {
          name: 'Target Url', type: 'text', rules: '', 
          model: 'target', cols: 12,
        },
      ]
    }
  }

}
</script>