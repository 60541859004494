<template>
  <div>
    <b-form @submit.prevent='submit'>
      <label for='file-input'>
        {{$t('Images')}}
      </label>  
      <b-form-file
        ref="file-input"
        id='multi-file-input'
        v-model="file"
        accept="image/*"
        class="mb-2"
        :multiple='multiple'
      />
      <!-- <b-button
        variant="primary"
        @click="file = null"
        size="sm"
      >
        {{$t('Reset')}}
      </b-button> -->

      <b-card class="p-1 " v-if='editObj != null'>
        <div class="d-flex">
          <div 
            class="image-holder"
            v-for='image in editObj.images' 
            :key='image.id'
          >
            <img 
              :src='(image.url)'    
              class="mx-1 "
              :class="checkIfImageDeleted(image) ? 'deleted' : ''"
              width="130px" 
              height="100px"
            />
            <!-- {{checkIfImageDeleted(image)}} -->
            <span class="deleteIconImages">
              <b-button
                variant="danger"
                :class="checkIfImageDeleted(image) ? 'btn rounded' : 'btn-icon rounded'"
                size='sm'
                @click='toggleDelete(image)'
              >
                <feather-icon v-if='!checkIfImageDeleted(image)' icon="Trash2Icon"  size="12"></feather-icon>
                <span v-else>{{'Removed'}}</span>
              </b-button>
            </span>
          </div>
        </div>
        <!-- <img v-else-if='$route.name == "Categories"' :src='editObj.icon_url' width="10%" height="100%"/> -->
        <!-- <img v-else :src='editObj.image_url' width="10%" height="100%"/> -->
        
          <!-- <div class="my-1" v-if='editObj.icon_url || (editObj.images[0] && editObj.images[0].url)'> -->
          <!-- <b-button
            variant="danger"
            class="btn"
            @click="deleteImg"
          >
            <feather-icon icon="Trash2Icon" />
            delete
          </b-button> -->
        <!-- </div> -->
      </b-card>
    </b-form>
  </div>
</template>

<script>
import { BFormFile, BButton, BForm, BCard, BCardFooter } from 'bootstrap-vue'
import {mapActions} from 'vuex'
export default {
  components: {
    BFormFile,
    BButton,
    BForm,
    BCard, BCardFooter
  },
  props: ['getImgData', 'editObj', 'multiple'],
  data: () => ({
    file: null,
    base64: {
      ext: '',
      file: '',
    },
    deletedImages: [],
  }),
  methods: {
    ...mapActions(['']),
    submit() {
      let data = [];
      // console.log(this.editObj.images.length)
      if(this.file) {
        if(this.file.length && this.file.length > 0) {
          this.file.forEach(el => {
            this.getBase64(el)
              .then(res => {
                data.push(res);
              })
          })
        }else {
          this.getBase64(this.file)
            .then(res => {
              data.push(res)
              // return true;
            })
        }
      }
      setTimeout(() => {
        this.addDeletedImagesToData(data)
        this.getImgData(data)
      }, 100) 
      // this.getImgForm()
    },
    toggleDelete(image) {
      if (this.checkIfImageDeleted(image)) {
        let index = this.deletedImages.findIndex(el => el == image.id);
        this.deletedImages.splice(index, 1);
      }else {
        this.deletedImages.push(image.id)
      }
      // console.log(image, this.deletedImages)
    },
    checkIfImageDeleted(image) {
      return this.deletedImages.includes(image.id);
    },
    addDeletedImagesToData(data) {
      if(this.editObj &&  this.editObj.images && this.editObj.images.length > 0) {
        this.deletedImages.forEach(el => {
          data.push({
            id: el,
            delete: true, 
          })
        })
      }

    }
       
        
  },
}
</script>
 