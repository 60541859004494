<template>
 <validation-observer ref="simpleRules" :key='1'>
  <b-form @submit.prevent='submit'>
    <b-row>
      <!-- name -->
      <b-col cols="12" v-if='!noTitlesRoutes'>
        <label v-if='title' for='title'>
          {{$t(title)}}
        </label>  
        <label v-else for='title'>
          {{$t('title')}}
        </label>  
        <validation-provider
          #default="{ errors }"
          rules="required"
          name='title'
        >
        <b-input-group >
            <b-form-input
              id="title"
              placeholder="title"
              v-model='baseData.titles[nameLang]'
              :state="errors.length > 0 ? false:null"
            />
            <!-- <b-input-group-append>
              <b-button variant="primary" @click='changeNameLang'>
                {{nameLang}}
              </b-button>
            </b-input-group-append> -->
        </b-input-group>
        <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-col>

      <b-col class="mt-2" cols="12" v-if='$route.name == "Testmonials"'>
        <label for='info'>
          {{$t('title')}}
        </label>  
        <validation-provider
          #default="{ errors }"
          rules=""
          name='info'
        >
        <b-input-group >
            <b-form-input
              id="info"
              placeholder="title"
              v-model='baseData.info'
              :state="errors.length > 0 ? false:null"
            />
        </b-input-group>
        <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-col>

      <!-- description  -->
      <b-col class='mt-2' cols="12" v-if='!noDescRoutes && !noDiscription'>
        <!-- <b-button
          :variant="lang == descLang ? 'primary' : 'outline-primary'"
          class="btn my-1 mx-1"
          v-for='lang in langs'
          :key='lang'
          @click='descLang = lang'
        >
          ({{lang}}) {{$t(lang)}}
        </b-button> -->
          <b-form-group
            :label="descTitle ? $t(descTitle) : $t('Description')"
            label-for="description"
          >
          <validation-provider
            #default="{ errors }"
            :rules="$route.name != 'Testmonials' ? 'required' : ''"
            name='description'
          >
            <quill-editor
              v-if='$route.name == "Sections" || quillEditorForDesc'
              v-model="baseData.descriptions[descLang]"
              placeholder="Description"
              :options="snowOption"
            />
            <b-form-textarea
              v-else
              id="description"
              type="text"
              placeholder="Description"
              rows="3"
              v-model="baseData.descriptions[descLang]"
            />
          <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>

    </b-row>
  </b-form>
 </validation-observer>
</template>

<script>
import {
  BRow, BCol, BFormGroup, BFormInput, BFormCheckbox, BForm, BButton, BFormTextarea, BInputGroup, BInputGroupAppend
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  required, email, confirmed, url, between, alpha, integer, password, min, digits, alphaDash, length,
} from '@validations'
import 'quill/dist/quill.core.css'
// eslint-disable-next-line
import 'quill/dist/quill.snow.css'
// eslint-disable-next-line
import 'quill/dist/quill.bubble.css'
import { quillEditor } from 'vue-quill-editor'

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BForm,
    BButton,
    BFormTextarea,
    BInputGroup,
    BInputGroupAppend,
    ValidationProvider,
    ValidationObserver,
    quillEditor
  },
  data() {
    return {
      baseData: {
        titles: {
          ar: '',
          en: ''
        },
        descriptions: {
          ar: '',
          en: ''
        },
        info: ''
      },
      nameLang: 'ar',
      descLang: 'ar',
      required,
      password,
      email,
      confirmed,
    }
  },
  watch: {
    editObj: function() {
      // console.log(this.editObj, this,baseData);
      this.$forceUpdate()
    }
  },
  props: ['getBaseData', 'editObj', 'quillEditorForDesc', 'noDiscription', 'title', 'descTitle'],
  mounted() {
    console.log('Base edit',this.editObj)
    if(this.editObj != null) {
     if(!this.editObj.titles) {
        this.baseData.titles = {
          ar: this.editObj.title.ar,
          en: this.editObj.title.ar,
        }
        this.baseData.descriptions = {...this.editObj.descriptions};
      }else {
        this.baseData.titles = {...this.editObj.titles};
        this.baseData.descriptions = {...this.editObj.descriptions};
      }
      this.baseData.info = this.editObj.info;
    }
    this.$forceUpdate()
  },
  methods: {
    submit() {
      return this.$refs.simpleRules.validate()
        .then(res => {
          this.valid = res;
          if (res == true) {
            this.getBaseData(this.baseData)
            return true;
          }else {
            return false;
          }
      })
    },
    // changeNameLang() {
    //   if(this.nameLang == 'ar') {
    //     this.nameLang = 'en'
    //   }else {
    //     this.nameLang = 'ar'
    //   }
    // },
    // checkEmptyLocale() {
    //   if(!this.baseData.titles.ar || !this.baseData.titles.en) {
    //     if(!this.baseData.titles.ar) {
    //       this.baseData.titles.ar = this.baseData.titles.en;
    //     }else {
    //       this.baseData.titles.en = this.baseData.titles.ar;
    //     }
    //   }
    // }
  },
  computed: {
    noDescRoutes() {
      let routes = [
        'Labels',
        'Categories',
        'JobCategories',
      ];
      return routes.includes(this.$route.name);
    },
    noTitlesRoutes() {
      let routes = [
        
      ];
      return routes.includes(this.$route.name);
    },
  },
  directives: {
    Ripple,
  },
}
</script>