<template>
<div class="">
  <layout-vertical>
    <b-overlay
      rounded="sm"
      :show='loading'
      variant='transparent'
      blur='2px'
    >
      <router-view  
        id='mainComp'
        :here='true'
        :openModal='openModal'
        :closeModal='closeModal'
        :confirmDelete='confirmDelete'
      />

    </b-overlay>

    <template #navbar="{ toggleVerticalMenuActive }">
      <navbar :toggle-vertical-menu-active="toggleVerticalMenuActive" />
    </template>

    <!-- <app-customizer
      v-if="showCustomizer"
      slot="customizer"
    /> -->
  </layout-vertical>
  <b-modal
    no-close-on-backdrop
    ref="layoutModal"
    cancel-variant="outline-secondary"
    :cancel-title="$t('Cancel')"
    :ok-title='$route.name == "Orders" && type == "Show" ? $t("Downlaod") : $t("Submit")'
    centered
    :size="routesLargerModal && type !=  'Import' ? 'xl' : 'lg'"
    :title="title"
    @ok="submitForm"
    @hidden='clearData'
  >
    <b-overlay
      rounded="sm"
      :show='modalLoading'
      variant='transparent'
      blur='2px'
    >
      <TestmonialsForm ref='categoryForm' :closeModal='closeModal' :editObj='editObj' v-if='$route.name == "Testmonials" && (type == "Create" || type == "Edit")'/> 
      <LabelsForm ref='showOrderForm' :closeModal='closeModal' :editObj='editObj' v-if='($route.name == "Labels" && (type == "Create" || type == "Edit"))' /> 
      <SolutionsForm ref='editOrderForm' :closeModal='closeModal' :editObj='editObj' v-if='($route.name == "Solutions" && (type == "Create" || type == "Edit"))'/> 
      <PagesForm ref='orderStatusForm' :closeModal='closeModal' :editObj='editObj' v-if='$route.name == "Pages"'/> 
      <SectionsForm ref='productsForm' :closeModal='closeModal' :editObj='editObj' v-if='$route.name == "Sections"'/> 
      
      <SlidersForm ref='slidersForm' :closeModal='closeModal' :editObj='editObj' v-if='$route.name == "Sliders"'/> 
      <BannersForm ref='bannersForm' :closeModal='closeModal' :editObj='editObj' v-if='$route.name == "Banners"'/> 
      <JobsForm ref='jobsForm' :closeModal='closeModal' :editObj='editObj' v-if='$route.name == "Jobs"'/> 
      <ProjectsForm ref='projectsForm' :closeModal='closeModal' :editObj='editObj' v-if='$route.name == "Projects"'/> 
      <CategoryForm ref='categoryForm' :closeModal='closeModal' :editObj='editObj' v-if='$route.name == "Categories" || $route.name == "JobCategories"'/> 
      
      <RolesForm ref='rolesForm' :closeModal='closeModal' :editObj='editObj' v-if='($route.name == "Roles")' /> 
      <AccountsForm ref='profileForm' :closeModal='closeModal' :editObj='editObj' v-if='$route.name == "Accounts"'/>
      <!-- <OrderForm ref='orderForm' :closeModal='closeModal' :editObj='editObj' v-if='($route.name == "Orders") && type == "Create"' /> 
      <CustomerOrders ref='orderForm' :closeModal='closeModal' :editObj='editObj' v-if='($route.name == "Customers") && type == "Orders"' /> 


      <ProductImportForm ref='productImportForm' :closeModal='closeModal' :editObj='editObj' v-if='$route.name == "Products" && type == "Import"'/> 

      <SuppliersForm ref='suppliersForm' :closeModal='closeModal' :editObj='editObj' v-if='$route.name == "Suppliers"'/> 

      <ShowReciept ref='showReciept' :closeModal='closeModal' :editObj='editObj' v-if='($route.name == "Reciepts" && type == "Show") || type == "ShowHomeReciept"'/> 
      <RecieptForm ref='recieptForm' :closeModal='closeModal' :editObj='editObj' v-if='$route.name == "Reciepts" && type != "Show"'/>  -->
    </b-overlay>
  </b-modal>

  

</div>

</template>

<script>
import LayoutVertical from '@core/layouts/layout-vertical/LayoutVertical.vue'
// import AppCustomizer from '@core/layouts/components/app-customizer/AppCustomizer.vue'
// import { $themeConfig } from '@themeConfig'
import Navbar from '../components/Navbar.vue';
import { BModal, BButton, BOverlay } from 'bootstrap-vue';
import { mapGetters } from 'vuex';
import ShowOrderForm from '@core/components/forms/ShowOrderForm';
import OrderStatusForm from '@core/components/forms/OrderStatusForm';
import ProductsForm from '@core/components/forms/ProductsForm';
import SuppliersForm from '@core/components/forms/SuppliersForm';
import ShowReciept from '@core/components/forms/ShowReciept';
import RecieptForm from '@core/components/forms/RecieptForm';
import OrderForm from '@core/components/forms/OrderForm';
import ProductImportForm from '@core/components/forms/ProductImportForm';
import EditOrderForm from '@core/components/forms/EditOrderForm';
import CustomerOrders from '@core/components/modal-views/CustomerOrders.vue'

import TestmonialsForm from '@core/components/forms/TestmonialsForm';
import LabelsForm from '@core/components/forms/LabelsForm';
import SolutionsForm from '@core/components/forms/SolutionsForm';
import PagesForm from '@core/components/forms/PagesForm';
import SectionsForm from '@core/components/forms/SectionsForm';

import SlidersForm from '@core/components/forms/SlidersForm';
import BannersForm from '@core/components/forms/BannersForm';
import JobsForm from '@core/components/forms/JobsForm';
import ProjectsForm from '@core/components/forms/ProjectsForm';
import CategoryForm from '@core/components/forms/CategoryForm';

import RolesForm from "@core/components/forms/RolesForm";
import AccountsForm from "@core/components/forms/AccountsForm";


import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    // AppCustomizer,
    BModal,
    LayoutVertical,
    Navbar,
    BOverlay,
    // ShowOrderForm,
    // OrderStatusForm,
    // ProductsForm,
    // SuppliersForm,
    // ShowReciept,
    // RecieptForm,
    // OrderForm,
    // ProductImportForm,
    // EditOrderForm,
    ToastificationContent,
    // CustomerOrders,

    TestmonialsForm,
    LabelsForm,
    SolutionsForm,
    PagesForm,
    SectionsForm,
    RolesForm,
    AccountsForm, 
    CategoryForm,
    SlidersForm,
    BannersForm,
    JobsForm,
    ProjectsForm,
  },
  data() {
    return {
      // showCustomizer: $themeConfig.layout.customizer,
      title: '',
      editObj: null,
      chosenForm: '',
      type: '',
    }
  },
  created() {
    // console.log(this.$refs.layoutModal)
    
  },
  methods: {
    submitForm(bvModalEvt) {
      if(!this.loading && !this.modalLoading) {
        bvModalEvt.preventDefault() 
        if(this.$route.name == "Testmonials" && (this.type == 'Create' || this.type == "Edit")) {
          this.$refs.categoryForm.formSubmit();
        }else if(this.$route.name == "Labels" && (this.type == "Create" || this.type == "Edit")) {
          this.$refs.showOrderForm.formSubmit();
        }else if((this.$route.name == "Solutions") && (this.type == "Create" || this.type == "Edit")) {
          this.$refs.editOrderForm.formSubmit();
        }else if(this.$route.name == 'Pages') {
          this.$refs.orderStatusForm.formSubmit();
        }else if(this.$route.name == 'Sections') {
          this.$refs.productsForm.formSubmit();
        }else if(this.$route.name == 'Sliders') {
          this.$refs.slidersForm.formSubmit();
        }else if(this.$route.name == "Projects") {
          this.$refs.projectsForm.formSubmit();
        }else if(this.$route.name == "Banners") {
          this.$refs.bannersForm.formSubmit();
        }else if(this.$route.name == "Jobs") {
          this.$refs.jobsForm.formSubmit()
        }else if(this.$route.name == "Categories" || this.$route.name == "JobCategories") {
          this.$refs.categoryForm.formSubmit()
        }else if(this.$route.name == "Roles") {
          this.$refs.rolesForm.formSubmit();
        }else if(this.$route.name == 'Accounts') {
          this.$refs.profileForm.formSubmit();
        }
        this.$store.commit('setModalLoading', false);
      }
    },
    
    openModal(obj, type) {
      let routeTitle;
      if(this.$route.name == 'Reciept') {
        routeTitle =  this.$t('Procurement');
      }else {    
        // routeTitle = this.$route.name.substr(0, this.$route.name.length - 1);
        console.log(type)
        routeTitle = this.$t(this.$route.name);
      }
      if (obj && obj != undefined && obj != null) {
        this.editObj = {...obj.item}
        this.title = this.$t(type)  + ' ' +  this.$t(routeTitle)
        this.type = type  || null;
      }else if(type == 'Import'){
        this.type = 'Import';
        this.title = this.$t('Import') + ' '  + routeTitle
      }else { 
        this.type = 'Create';
        this.title = this.$t('Create New') + ' '  + routeTitle
      }
      this.$refs['layoutModal'].show();
      this.$store.commit
    },
    closeModal() {
      this.$refs['layoutModal'].hide()
      this.$store.commit('setModalLoading', false);
    },
    confirmDelete(deleteFunction, item) {
      this.$swal({
        title: this.$t('Are you sure') + '?',
        text: this.$t("You won't be able to revert this"),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.$t('YesDelete'),
        customClass: {
          confirmButton: 'btn btn-danger',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        console.log(result.isConfirmed)
        if(result.isConfirmed == true) {
          deleteFunction(item.item.id)
            .then(res => {
              if (result.value) {
                this.$swal({
                  icon: 'success',
                  title: this.$t('Deleted'),
                  text: this.$t('Your file has been deleted'),
                  customClass: {
                    confirmButton: 'btn btn-success',
                  },
                })
              }
            }).catch(err => {
              console.log(err)
              this.$swal({
                icon: 'error',
                title: 'Error while Deleting Item!',
                text: 'Your file has not been deleted.',
                customClass: {
                  confirmButton: 'btn btn-outline-danger',
                },
              })
            })
        }
      })
    },
    clearData() {
      this.editObj = null;
      this.$store.state.category = null;
      this.$store.commit('setModalLoading', false);
    }
  },
  computed: {
    ...mapGetters(['loading', 'modalLoading']),
    routeName() {
      return this.$route.name;
    },
    routesLargerModal() {
      let routes = [
        'Reciepts',
        'Products',
        'Home',
        'Orders',
        // 'Solutions',
      ]
      return routes.includes(this.$route.name); 
    },
    bannerCategoryRoutes() {
      return this.$route.name == 'Categories' ||  this.$route.name == 'Banners';
    }
  }
}
</script>


<style lang='scss'>
@media print {

  // Global Styles
  body {
    background-color: transparent !important;
  }
  nav.header-navbar {
    display: none;
  }
  .main-menu {
    display: none;
  }
  .header-navbar-shadow {
    display: none !important;
  }
  .content.app-content {
    margin-left: 0;
    padding-top: 2rem !important;
  }
  footer.footer {
    display: none;
  }
  .card {
    background-color: transparent;
    box-shadow: none;
  }
  .customizer-toggle {
    display: none !important;
  }
  #mainComp {
    display: none !important;

  }
  button {
    display: none !important;

  }
  .content-header {
    display: none !important;
  }
  .moda-footer {
    display: none !important;
  }
  .modal-header {
    display: none !important;
  }
  .modal-dialog-centered {
    display: block !important;
    // align-items: none;
  }
  .modal-dialog {
    max-width: 100%;
    width: 100%;
    // margin-top: -200px;
  }
}
</style>